import React from 'react';

export const ErrorPage = (props) => {
    return (
        <div>
            Error {props.location.state ? props.location.state.status : " 404"}
        </div>
    );

}

export default ErrorPage;