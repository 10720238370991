import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "../styles/createCoupon.css";
import FooterCoupon from "../components/FooterCoupon";
import SwipeButton from "../components/Buttons/SwipeButton";
import couponMainActions from "../components/modules/couponMainActions";
import ActionSheet from "actionsheet-react";
import { ReactComponent as CloseIcon } from "../assets/images/common/closeIcon.svg";
import useAnalytics from "../utils/useAnalytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const MobileCoupon = (props) => {
  const { token: locationId } = props.match.params;
  let { lang } = props.match.params;
  if (lang === "es") {
    lang = "sp";
  }

  const [couponData, setCouponData] = useState();
  const ref = useRef();
  const [contentVisible, setContentVisible] = useState(true);
  const { sendCommEngagementEvent } = useAnalytics(
    couponData?.GATrackingNumber
  );

  const handleOpen = () => {
    ref.current.open();
  };

  const handleClose = () => {
    onClose();
    ref.current.close();
  };

  useEffect(() => {
    if (couponData) {
      handleOpen();
    }
  }, [couponData]);

  const GAEventTracking = () => {
    sendCommEngagementEvent({ widget: "Mobile Coupon", status: "start" });
  };

  useEffect(() => {
    if (couponData) {
      sendCommEngagementEvent({ widget: "Mobile Coupon", status: "load" });
    }
  }, [couponData]);

  useEffect(() => {
    const fill = async () => {
      let result = await couponMainActions.getCoupon(locationId);
      if (result.success) {
        setCouponData(result.data);
      }
    };
    if (locationId) {
      fill();
    }
  }, [locationId]);

  if (!couponData) return null;

  const title =
    couponData.title?.find((x) => x.language === lang)?.text ||
    couponData.title?.find((x) => x.language === "en")?.text;
  const description =
    couponData.description?.find((x) => x.language === lang)?.text ||
    couponData.description?.find((x) => x.language === "en")?.text;
  const buttonTexts =
    couponData.buttonTexts?.find((x) => x.language === lang)?.text ||
    couponData.buttonTexts?.find((x) => x.language === "en")?.text;
  const smsText =
    couponData.smsTemplate?.find((x) => x.language === lang)?.text ||
    couponData.smsTemplate?.find((x) => x.language === "en")?.text;

  const getContent = () => {
    return (
      <div
        className={`phone-under-nav-wrapper`}
        style={{ backgroundColor: couponData.primaryBackgroundColor }}
      >
        <div className="nav-header-border" />
        <div
          onClick={handleClose}
          style={{
            padding: 10,
            cursor: "pointer",
            right: 20,
            top: 15,
            position: "absolute",
          }}
        >
          <CloseIcon stroke={couponData.titleColor} />
        </div>
        <div className="logo-item">
          {couponData.logoImageUrl && (
            <img src={couponData.logoImageUrl} alt="Logo" />
          )}
        </div>
        <div className="title-item">
          {title && (
            <div className="title" style={{ color: couponData.titleColor }}>
              {title}
            </div>
          )}
        </div>
        <div className="note-item">
          {description && (
            <div
              className="note"
              style={{ color: couponData.descriptionColor }}
            >
              {description}
            </div>
          )}
        </div>
        <div className="button-item">
          <SwipeButton
            backgroundColor={couponData.backgroundColor}
            color={couponData.buttonColor}
            // fontSize={customCouponObj.swipeFontSize}
            text={buttonTexts}
            phoneNumber={couponData.organization_number}
            smsText={smsText}
            onSuccess={handleClose}
            lang={lang}
            eventTracking={GAEventTracking}
          />
        </div>
        <FooterCoupon
          lang={lang}
          termsLink={couponData.termsConditionsLink}
          theme={couponData.theme}
        />
      </div>
    );
  };

  const onClose = () => {
    window.top.postMessage("matador_coupon_message_hidden", "*");
    setContentVisible(false);
  };

  if (!contentVisible) return null;

  return (
    <ActionSheet
      closeOnBgTap={false}
      sheetStyle={{ backgroundColor: "transparent" }}
      ref={ref}
      onClose={onClose}
    >
      <Container>{getContent()}</Container>
    </ActionSheet>
  );
};

export default MobileCoupon;
