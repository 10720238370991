import React, { useEffect, useState } from 'react';
import Swipeout from 'rc-swipeout';
import { SVGSwipeIcon } from '../Icons/Icons';
import 'rc-swipeout/assets/index.css';
import { strings } from '../../utils/localization';

const SwipeButton = ({ backgroundColor, color, fontSize, text, phoneNumber, smsText, lang, eventTracking }) => {
    const [touch, setTouch] = useState(false);
    const [isGoogleApp, setIsGoogleApp] = useState(false);
    const link = `sms://${phoneNumber}${getMobileOperatingSystem() === 'iOS' ? '/&' : '?'}body=${smsText}`;
    const isSafariOrIos = () => {
        if (getMobileOperatingSystem() === 'iOS') {
            return true
        }
        return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) && !/CriOS|FxiOS/.test(navigator.userAgent);
    }
    
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        const isGoogleApp = userAgent.includes('GSA/');

        setIsGoogleApp(isGoogleApp)
      }, []);

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Android/i)) {
            return 'Android';
        } else {
            return 'unknown';
        }
    }

    const onOpen = () => {
        eventTracking()

        if (!isSafariOrIos()) {
            window.location.href = link;
        }
    };

    const copyToClipboard = async () => {
        const message = { eventName: 'open_sms_matador', phoneNumber, text: smsText, system: getMobileOperatingSystem() };
        window.top.postMessage(message, "*");
    }

    const ClipBoardButton = ({ btnLang, tracking }) => {
        let buttonTxt

        if (btnLang === 'sp' || btnLang === 'es') {
            buttonTxt = strings._props.es.CLICK_TO_START
        } else if (btnLang === 'fr') {
            buttonTxt = strings._props.fr.CLICK_TO_START
        } else {
            buttonTxt = strings._props.en.CLICK_TO_START
        }

        const handleClick = () => {
            tracking();
            copyToClipboard()
        }

        return (
            <div
                style={{ textDecoration: 'none', textAlign: 'center', width: '100%', fontWeight: 'bold', zIndex: 99999 }}
                onClick={handleClick}
            >
                <div style={{
                    backgroundColor: backgroundColor, cursor: 'pointer', width: '100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} className="swipe-to-start-button swipe-to-start-button-animation">
                    <div
                        className="text"
                        style={{
                            color: color,
                            fontSize: fontSize,
                        }}
                    >
                        {buttonTxt}
                    </div>
                </div>
            </div>
        );
    }

    const SafariButton = ({ btnLang, tracking }) => {
        let buttonTxt

        if (btnLang === 'sp' || btnLang === 'es') {
            buttonTxt = strings._props.es.CLICK_TO_START
        } else if (btnLang === 'fr') {
            buttonTxt = strings._props.fr.CLICK_TO_START
        } else {
            buttonTxt = strings._props.en.CLICK_TO_START
        }

        const handleClick = () => {
            tracking();
        }

        return (
            <a
                style={{ textDecoration: 'none', textAlign: 'center', width: '100%', fontWeight: 'bold', zIndex: 99999 }}
                href={link}
                target="_blank" rel="noopener noreferrer"
                aria-label='sms link'
                onClick={handleClick}
            >
                <div style={{
                    backgroundColor: backgroundColor, cursor: 'pointer', width: '100%', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                }} className="swipe-to-start-button swipe-to-start-button-animation">
                    <div
                        className="text"
                        style={{
                            color: color,
                            fontSize: fontSize,
                        }}
                    >
                        {buttonTxt}
                    </div>
                </div>
            </a>
        );
    };

    if (isGoogleApp) {
        return (
            <ClipBoardButton btnLang={lang} tracking={eventTracking} />
        )
    }

    if (isSafariOrIos()) {
        return <SafariButton btnLang={lang} tracking={eventTracking} />;
    }

    return (
        <div>
            <Swipeout
                autoClose
                className="swipe-to-start-button "
                style={{ backgroundColor: backgroundColor }}
                left={[
                    {
                        text: '',
                        style: { backgroundColor: backgroundColor, color: color, fontSize: fontSize, width: '250px' }
                    }
                ]}
                onOpen={onOpen}
            >
                <div className="icon-with-text" style={{ backgroundColor: backgroundColor }}>
                    <div
                        style={touch ? { animation: 'none' } : {}}
                        className="icon-wrapper"
                        onTouchStart={() => {
                            setTouch(!touch);
                        }}
                        onTouchEnd={() => {
                            setTouch(false);
                        }}
                    >
                        <SVGSwipeIcon color={backgroundColor} />
                    </div>
                    <div className="text" style={{ color: color, fontSize: fontSize }}>
                        {text}
                    </div>
                </div>
            </Swipeout>
        </div>
    );
};

export default SwipeButton;
