export const getFontFamily = (chatData) => {
  if (!chatData) return "Arial, 'Helvetica Neue', sans-serif";

  const token = chatData._token;
  let fontFamily = "Arial, 'Helvetica Neue', sans-serif";

  if (
    token === "12423abb841941c552815dac59ee48477267ec50" ||
    token === "489734309b0baf97129d98198f67cf878bacbb85" ||
    token === "25e67694cd84d6cb8471cd7b765077c14315a923" ||
    token === "aeb87d7628e0145574fec7667902769095eab957"
  ) {
    // MAT-3739
    fontFamily = "Arial";
  }

  return fontFamily;
};
