import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

import { default as CloseIcon } from "./assets/closeIcon.svg";
import { default as PauseIcon } from "./assets/pauseIcon.svg";
import { default as SoundMediumIcon } from "./assets/soundMedium.svg";
import { default as SoundOffIcon } from "./assets/soundOff.svg";
import { default as SoundOnIcon } from "./assets/soundOn.svg";
import { default as PlayIcon } from "./assets/playIcon.svg";
import Spinner from "../Loader/Spinner";
import ProgressBar from "./ProgressBar";
import NavigationIcon from "./NavigationIcon";

const Container = styled.div`
  video {
    object-fit: ${(props) => props.cover};
  }
`;
const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 3;
  padding: 16px;
  backdrop-filter: contrast(0.83);
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.p`
  font-size: 11px;
  color: #ffffff;
  margin: 0px;
`;

const Thumbnail = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 14px;
  object-fit: cover;
`;

const Icon = styled.img`
  max-height: 25px;
  cursor: pointer;
  margin-left: 24px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

const LoaderSection = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoPlayer = ({
  videoUrl,
  thumbnailUrl,
  title,
  closeVideo,
  nextStory,
  stories,
  activeStory,
  previousStory,
}) => {
  const [volume, setVolume] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [played, setPlayed] = useState(0);

  const handlePreviousStory = () => {
    setIsReady(false)
    previousStory()
  }

  const handleNextStory = () => {
    setIsReady(false)
    nextStory()
  }

  const [isHorizontalVideo, setIsHorizontalVideo] = useState(false);

  useEffect(() => {
    const videoElement = document.querySelector("video");
    if (videoElement && isReady) {
      setIsHorizontalVideo(false)
      const videoAspectRatio =
        videoElement.videoHeight / videoElement.videoWidth;
      const containerAspectRatio =
        videoElement.clientHeight / videoElement.clientWidth;

      setIsHorizontalVideo(videoAspectRatio < containerAspectRatio);
    }
  }, [videoUrl, isReady, played]);

  if (!videoUrl) {
    return false;
  }

  const changeVolume = () => {
    if (volume === 1) {
      return setVolume(0);
    }

    setVolume((prevVolume) => prevVolume + 0.5);
  };

  const togglePlaying = () => {
    setIsPlaying((prevState) => !prevState);
  };

  const getSoundIcon = () => {
    if (volume === 0) {
      return SoundOffIcon;
    } else if (volume === 0.5) {
      return SoundMediumIcon;
    }
    return SoundOnIcon;
  };

  const onReady = () => {
    setIsReady(true);
  };

  const onProgress = (data) => {
    setPlayed(data.played * 100);
  };


  return (
    <Container
      id="video-player"
      cover={isHorizontalVideo ? "contain" : "cover"}
    >
      <NavigationIcon position="left" onClick={handlePreviousStory} />
      <Header>
        <ProgressBar
          stories={stories}
          activeStory={activeStory}
          progress={played}
          type="video"
        />
        <Content>
          <LeftContent>
            {thumbnailUrl && <Thumbnail src={thumbnailUrl} alt={title} />}
            <Label id="video-title">{title}</Label>
          </LeftContent>
          <RightContent>
            <Icon
              alt="Play"
              src={isPlaying ? PauseIcon : PlayIcon}
              onClick={togglePlaying}
            />
            <Icon alt="Sound" src={getSoundIcon()} onClick={changeVolume} />
            <Icon alt="Close" src={CloseIcon} onClick={closeVideo} />
          </RightContent>
        </Content>
      </Header>
      <LoaderSection>
        <Spinner />
      </LoaderSection>
      <ReactPlayer
        style={{
          position: "absolute",
          top: 0,
          zIndex: 2,
          backdropFilter: "blur(10px)",
        }}
        width="100%"
        muted={!volume}
        height={isReady ? "100%" : "0%"}
        url={videoUrl}
        volume={volume}
        playing={isPlaying}
        playsinline
        onReady={onReady}
        onEnded={handleNextStory}
        onProgress={onProgress}
      />
      <NavigationIcon position="right" onClick={handleNextStory} />
    </Container>
  );
};

export default memo(VideoPlayer);
