import React, { useEffect, useState } from "react";
import DefaultView from "./DefaultView";
import moment from "moment";
import querystring from "querystring";
import chatSettingsMainActions from "../../../components/modules/chatSettingsMainActions";
import Cookies from "universal-cookie";
import QuestionsApi from "../../../api/questions-api";
import PageLoader from "../../../components/PageLoader";

import ChatBoxPreviewLegacy from "./Legacy/ChatBoxPreviewLegacy";
import { getLanguage, strings } from "../../../utils/localization";
import SelectOrganization from "../ClientUIX/SelectOrganization";
import CustomSubmitForm from "../ClientUIX/CustomSubmitForm";
import LeadSubmitForm from "./Form/LeadSubmitForm";
import useAnalytics from "../../../utils/useAnalytics";

const cookies = new Cookies();

const ChatContent = (props) => {
  const {
    chatData,
    triggers,
    clearValues,
    match,
    isClientUIX,
    selectedOrganization,
    setSelectedOrganization,
    questionPicked,
    setQuestionPicked,
    customActionData,
    submissionGAevent,
    isCustomSubmitForm,
    setIsClientUIX,
    setIsCustomSubmitForm,
    setShowBackIcon,
    activeLocation,
    language,
    activeToken,
    initialStepType,
    goBack,
    setActiveLocation,
    setQuestionStatus,
  } = props;
  const [viewIndex, setViewIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState();
  const [parentURL, setParentURL] = useState();
  const [backView, setBackView] = useState();
  const [questionId, setQuestionId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [listQuestions, setListQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [dateQuestion, setDateQuestion] = useState(moment());
  const [typingTwo, setTypingTwo] = useState(true);
  const [typingThree, setTypingThree] = useState(true);
  const [_trigger_id, set_trigger_id] = useState();
  const [messagesEndRef, setMessagesEndRef] = useState();
  const [clientUIXToken, setClientUIXToken] = useState("");
  const [answer, setAnswer] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [uixFormDescription, setUixFormDescription] = useState("");
  const { sendCtaInteractionEvent, sendFormEngagementEvent } = useAnalytics(
    chatData?.GATrackingNumber
  );

  useEffect(() => {
    loadData();
    setLanguage();
  }, []);

  useEffect(() => {
    sendFormEngagementEvent({ widget: "Connect" });
  }, [phoneNumber, name, sendFormEngagementEvent]);

  useEffect(() => {
    if (customActionData) {
      handleCustomAction();
    }
  }, [customActionData]);

  useEffect(() => {
    if (parentURL) {
      setTriggers({ triggers, parentURL });
    }
  }, [triggers, parentURL]);

  const handleCustomAction = () => {
    const { make, vin, year, model } = customActionData;
    let text = `I'm interested in this ${year} ${make} ${model} ${vin}`;

    if (chatData?._token === "8c6c92ccee38405b378616e404a2009e90022a8f") {
      text = `I'm interested in this ${make} ${model}`;
    }
    handleSelectQuestion(text);
  };

  useEffect(() => {
    onBackClick();
  }, [clearValues]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.type === "URL_CHANGE") {
        setParentURL(event.data.newURL);
        setTriggers({ triggers, parentURL: event.data.newURL });
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const setLanguage = () => {
    let languageValue = match.params.lang ? match.params.lang : "en";
    if (languageValue === "sp") {
      languageValue = "es";
    }

    strings.setLanguage(languageValue);
  };

  const loadData = async () => {
    const itemIdValue = props.match.params.token ? props.match.params.token : 0;
    setItemId(itemIdValue);

    let params = querystring.parse(props.location.search);
    let urlValue = decodeURI(params["?url"]);

    if (props.location.hash) {
      const newUrl = urlValue + props.location.hash;
      setParentURL(newUrl);
      urlValue = newUrl;
    } else {
      setParentURL(urlValue);
    }

    if (triggers) {
      setLoading(false);
      setTriggers({ triggers, parentURL: urlValue });
    }
  };

  const setTriggers = ({ triggers, parentURL }) => {
    let hideConditionMatched = false;
    let matchedTriggerId = null; // To store the ID of the trigger that matches the conditions.

    triggers.forEach((trig) => {
      if (trig.enabled) {
        trig.conditions.url.forEach((condition) => {
          let processedLink = condition.link
            .replace(/(^\w+:|^)\/\/(www\.)?/, "")
            .replace(/\/$/, "");
          let conditionMatch = false;

          switch (condition.condition) {
            case "contains":
              conditionMatch = parentURL.includes(processedLink);
              break;
            case "starts_with":
              conditionMatch = parentURL.startsWith(processedLink);
              break;
            case "matches":
              conditionMatch = parentURL === processedLink;
              break;
            case "ends_with":
              conditionMatch = parentURL.endsWith(processedLink);
              break;
            default:
              break;
          }

          if (conditionMatch) {
            // Prioritize hide conditions
            if (condition.action === "hide") {
              hideConditionMatched = true;
              matchedTriggerId = trig._id; // Capture the ID of the matching trigger
            } else if (condition.action === "show" && !hideConditionMatched) {
              // Show only if no hide condition has been matched yet
              matchedTriggerId = trig._id; // Capture the ID of the matching trigger
            }
          }
        });
      }
    });

    // If matchedTriggerId is not null, call renderList with the questions from the matched trigger
    if (matchedTriggerId !== null) {
      const matchedTrigger = triggers.find(
        (trig) => trig._id === matchedTriggerId
      );
      if (matchedTrigger) {
        renderList(matchedTrigger.questions, false, matchedTriggerId);
      }
    } else {
      // Fallback to default trigger action if no specific conditions are matched or if showContent is true without a specific match
      const defaultTrigger = triggers.find((trig) => trig.isDefault);
      if (defaultTrigger) {
        renderList(defaultTrigger.questions, false, defaultTrigger._id);
      }
    }
  };

  const handleCalculatetriggerCount = async (trigger_id) => {
    if (trigger_id) {
      await chatSettingsMainActions.setSendTriggerId(trigger_id);
    }
  };

  const renderList = (questions, _, _trigger_id = null) => {
    setListQuestions(questions);
    if (_trigger_id) {
      set_trigger_id(_trigger_id);
    }
    handleCalculatetriggerCount(_trigger_id);
  };

  const isValidPhoneNumber = (value) => {
    const phone = value || phoneNumber;
    if (phone?.startsWith("04")) {
      return phone.length === 10;
    }
    return phone?.length === 11;
  };

  const getPhoneNumber = () => {
    if (phoneNumber.startsWith("04")) {
      return `61${phoneNumber.substring(1)}`;
    }
    return phoneNumber;
  };

  const getVehicleData = () => {
    const {
      vin,
      make,
      model,
      year,
      trim,
      stock,
      mileage,
      exteriorcolor,
      interiorcolor,
    } = customActionData;

    const data = {
      ...(vin && { vin }),
      ...(make && { make }),
      ...(model && { model }),
      ...(year && { year: year?.toString() }),
      ...(trim && { trim }),
      ...(stock && { stock: stock?.toString() }),
      ...(mileage && { mileage: mileage?.toString() }),
    };

    if (exteriorcolor || interiorcolor) {
      data.color = {
        ...(exteriorcolor && { exterior: exteriorcolor }),
        ...(interiorcolor && { interior: interiorcolor }),
      };
    }

    return [data];
  };

  const sendQuestion = async (cb) => {
    if (phoneNumber && isValidPhoneNumber()) {
      let param = {
        phone_number: getPhoneNumber(),
        question: selectedQuestion,
        link: parentURL,
        _trigger_id: _trigger_id,
        language: getLanguage(props.match.params.lang),
      };

      if (questionId && questionId !== "CUSTOM_QUESTION") {
        param._question_id = questionId;
      }

      if (customActionData) {
        const vehicleData = getVehicleData();
        param.vehicle = vehicleData;

        const nonEmptyVehicleData = vehicleData.filter(
          (data) => Object.keys(data).length > 0
        );
        if (nonEmptyVehicleData.length > 0) {
          param.vehicle = nonEmptyVehicleData;

          const vehicleDataString = nonEmptyVehicleData
            .map((data) => {
              return Object.keys(data)
                .map((key) => {
                  return `${data[key]}`;
                })
                .join(" ");
            })
            .join(" ");
          param.question = `I'm interested in this ${vehicleDataString}`;
        }
      }

      if (chatData?.firstAndLastNameSeparately) {
        param.firstName = firstName;
        param.lastName = lastName;
      } else if (name) {
        param.firstName = name || firstName;
      }

      QuestionsApi.sendQuestion({
        itemId:
          activeToken || clientUIXToken || activeLocation?._token || itemId,
        param,
      }).then((response) => {
        cb && cb(response);
      });
    }
  };

  const scrollContentArea = () => {
    var element = document.getElementById("content-area");
    if (element) {
      element.scroll({ top: element.scrollHeight, behavior: "smooth" });
    }
  };

  const onBackClick = () => {
    setViewIndex(1);
    setSelectedQuestion("");
    setAnswer("");
    setQuestionType("");
    setBackView("backView");
    setQuestionId(null);
    setPhoneNumber("");

    setTimeout(() => {
      setBackView("");
      setTypingTwo(true);
      setTypingThree(true);
      setTimeout(() => {
        scrollContentArea();
      }, 3000);
    }, 1000);

    if (setActiveLocation) {
      setActiveLocation(null);
    }
  };

  const gtag = () => {
    if (submissionGAevent) {
      submissionGAevent();
    }
  };

  const submit = ({ viewIndex, send }) => {
    setViewIndex(viewIndex);

    if (send) {
      sendQuestion(() => {
        setTimeout(() => {
          setTypingThree(false);
          scrollToBottom();
        }, 2000);
      }, gtag());
    } else {
      setTimeout(() => {
        setTypingThree(false);
        scrollToBottom();
      }, 2000);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef) {
      const scrollHeight = messagesEndRef.scrollHeight;
      const height = messagesEndRef.clientHeight;
      const maxScrollTop = scrollHeight - height;

      messagesEndRef.scrollTo({
        top: maxScrollTop > 0 ? maxScrollTop : 0,
        behavior: "smooth",
      });
    }
  };

  const handleSelectingCustomQuestion = () => {
    setQuestionId("CUSTOM_QUESTION");
  };

  const handleSelectQuestion = (question, type, answer, customQuestion) => {
    if (initialStepType !== "Questions -> Locations" && !customQuestion) {
      setViewIndex(2);
    }

    if (initialStepType !== "Questions -> Locations" && customQuestion) {
      setViewIndex(2);
    }

    if (customQuestion) {
      handleSelectingCustomQuestion(question);
    }

    setSelectedQuestion(question);
    setQuestionType(type);
    setDateQuestion(moment().format("MM.DD.YYYY HH:MM a"));
    setAnswer(answer);

    setTimeout(() => {
      setTypingTwo(false);
    }, 3000);
  };

  const renderLoad = () => {
    cookies.set("token", props.match.params.token);
    cookies.set("lang", props.match.params.lang);
    return <PageLoader />;
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    setPhoneNumber(pasteValue);
    isValidPhoneNumber(pasteValue);
  };

  const onQuestionClick = (questionItem) => {
    if (questionItem.useRedirect && questionItem.redirectedURL) {
      sendCtaInteractionEvent({ widget: "Connect" });
      return triggerUrlRedirection({ url: questionItem.redirectedURL });
    }
    if (questionItem._token) {
      setClientUIXToken(questionItem._token);
    }
    setQuestionId(questionItem.id);
    setQuestionStatus(questionItem.status);
    handleSelectQuestion(
      questionItem.question,
      questionItem.type,
      questionItem.answer
    );
    sendCtaInteractionEvent({ widget: "Connect" });
  };

  const triggerUrlRedirection = ({ url }) => {
    window.top.postMessage(
      {
        type: "MATADOR_REDIRECT_TO_URL",
        url,
      },
      "*"
    );
  };

  if (loading && !chatData) {
    renderLoad();
  }

  if (isClientUIX && !questionId) {
    return (
      <SelectOrganization
        chatData={chatData}
        listQuestions={listQuestions}
        triggers={triggers}
        handleSelectQuestion={handleSelectQuestion}
        setQuestionId={setQuestionId}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        setClientUIXToken={setClientUIXToken}
        questionPicked={questionPicked}
        setQuestionPicked={setQuestionPicked}
        setLoading={setLoading}
        setUixFormDescription={setUixFormDescription}
        setIsClientUIX={setIsClientUIX}
        onQuestionClick={onQuestionClick}
        setIsCustomSubmitForm={setIsCustomSubmitForm}
        setTriggers={setTriggers}
        setShowBackIcon={setShowBackIcon}
        parentURL={parentURL}
      />
    );
  }

  if (
    viewIndex === 1 ||
    (viewIndex === 1 && initialStepType === "Questions -> Locations")
  ) {
    return (
      <DefaultView
        questions={listQuestions}
        onQuestionClick={onQuestionClick}
        chatData={chatData}
        triggers={triggers}
        selectedQuestion={selectedQuestion}
        handleSelectQuestion={handleSelectQuestion}
        setSelectedQuestion={setSelectedQuestion}
        token={props.match.params.token}
        customActionData={customActionData}
        language={getLanguage(language)}
        questionId={questionId}
        setViewIndex={setViewIndex}
        viewIndex={viewIndex}
        goBack={onBackClick}
      />
    );
  }

  if (
    (isCustomSubmitForm && viewIndex === 2) ||
    (isCustomSubmitForm && viewIndex === 3 && questionType !== "SMART_ANSWER")
  ) {
    return (
      <CustomSubmitForm
        chatData={chatData}
        submit={submit}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        name={name}
        setName={setName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        description={uixFormDescription}
        viewIndex={viewIndex}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        firstAndLastNameSeparately={chatData.firstAndLastNameSeparately}
        scrollToBottom={scrollToBottom}
        goBack={onBackClick}
      />
    );
  }

  if (viewIndex === 2 || viewIndex === 3) {
    return (
      <LeadSubmitForm
        chatData={chatData}
        submit={submit}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        name={name}
        setName={setName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        description={uixFormDescription}
        answer={answer}
        dateQuestion={dateQuestion}
        typingTwo={typingTwo}
        typingThree={typingThree}
        viewIndex={viewIndex}
        questionType={questionType}
        scrollToBottom={scrollToBottom}
        isCustomSubmitForm={isCustomSubmitForm}
        firstAndLastNameSeparately={chatData.firstAndLastNameSeparately}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        goBack={onBackClick}
        currentUrl={parentURL}
      />
    );
  }

  return (
    <ChatBoxPreviewLegacy
      {...props}
      viewIndex={viewIndex}
      setViewIndex={(val) => setViewIndex(val)}
      setLoading={(value) => setLoading(value)}
      chatData={chatData}
      submit={submit}
      selectedQuestion={selectedQuestion}
      backView={backView}
      listQuestions={listQuestions}
      dateQuestion={dateQuestion}
      typingTwo={typingTwo}
      typingThree={typingThree}
      setPhoneNumber={setPhoneNumber}
      setSelectedQuestion={setSelectedQuestion}
      setQuestionId={setQuestionId}
      setMessagesEndRef={setMessagesEndRef}
      phoneNumber={phoneNumber}
      defaultCountryFlag={
        chatData?.organization_country_code?.toLowerCase() || "us"
      }
      isValidPhoneNumber={isValidPhoneNumber()}
      getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
      isClientUIX={isClientUIX}
      questionType={questionType}
      answer={answer}
      scrollToBottom={scrollToBottom}
      name={name}
      setName={setName}
      isCustomSubmitForm={isCustomSubmitForm}
    />
  );
};

export default ChatContent;
