import React, { useEffect, useState } from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MarkerIcon from "../../../assets/images/common/MarkerIcon.svg";
import styled from "styled-components";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import HardcodedData from "./data/hardcodedInfo.json";

const StyledMap = styled(Map)`
  height: 227px;
  margin: 0 16px;
  border-radius: 12px;
`;

const provider = new OpenStreetMapProvider();

const LocationMap = ({ addressOfLocation, token }) => {
  const [bounds, setBounds] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [hasMarkers, setHasMarkers] = useState();

  const Icon = new L.icon({
    iconUrl: MarkerIcon,
    iconSize: [14, 18],
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getBounds();
  }, [addresses]);

  const getBounds = () => {
    if (addresses && addresses.length > 0) {
      const data = new L.latLngBounds(addresses);
      setBounds(data);
    }
  };

  const getData = async () => {
    const locations = [];

    for (const address of addressOfLocation) {
      let data = await getCoordinates(address);
      if (!data || data?.length === 0) {
        const item = HardcodedData.find((x) => x.token === token);
        if (item) {
          data = item.info;
        }
      }
      if (data && data[0]?.x && data[0]?.y) {
        setHasMarkers(true);
        locations.push([data[0].y, data[0].x]);
      }
    }

    setAddresses(locations);
  };

  const getCoordinates = async (address) => {
    return provider.search({ query: address });
  };

  const renderMarkers = () => {
    return addresses?.map((location, id) => {
      const [lat, lng] = location;

      return <Marker position={[lat, lng]} key={id} icon={Icon} />;
    });
  };

  if (!hasMarkers) return null;

  return (
    <StyledMap
      bounds={bounds}
      zoomControl
      boundsOptions={{ padding: L.point(13, 13) }}
      scrollWheelZoom={false}
      style={{ zIndex: 100, position: "relative" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderMarkers()}
    </StyledMap>
  );
};

export default LocationMap;
