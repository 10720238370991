import React, { useEffect, useState } from "react";
import LightFooter from "../components/Footer/LightFooter";
import DefaultFooter from "../components/Footer/DefaultFooter";
import NextButton from "../components/Buttons/NextButton";
import TextInput from "../components/Fields/TextInput";
import { validatePhoneNumber, validateName } from "../utils/validation";
import styled from "styled-components";
import ThankYou from "../components/ThankYou/ThankYou";
import { getLanguage, strings } from "../utils/localization";
import PhoneField from "../components/Fields/PhoneField";
import Note from "../components/Notes/Note";
import QuestionsApi from "../api/questions-api";
import querystring from "querystring";
import chatSettingsMainActions from "../components/modules/chatSettingsMainActions";
import parsePhoneNumber from "libphonenumber-js";
import useAnalytics from "../utils/useAnalytics";

const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 10px #00000029;
  border-radius: 4px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-left: 7px;
`;

const TextMeView = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [parentURL, setParentURL] = useState();
  const [chatData, setChatData] = useState();
  const { sendCommSubmissionEvent, sendCommEngagementEvent } = useAnalytics(
    chatData?.GATrackingNumber
  );

  const { theme } = props;
  const footer = theme === "default" ? <DefaultFooter /> : <LightFooter />;

  useEffect(() => {
    if (chatData) {
      sendCommEngagementEvent({ widget: "Connect", status: "load" });
    }
  }, [chatData]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let params = querystring.parse(props.location.search);
    let urlValue = decodeURI(params["?url"]);

    const itemIdValue = props.match.params.token ? props.match.params.token : 0;
    let languageValue = props.match.params.lang
      ? props.match.params.lang
      : "en";
    let result = await chatSettingsMainActions.getSingleForWidget(
      { language: languageValue },
      itemIdValue
    );

    if (result.success) {
      setChatData(result.data.chatData);
      sendCommSubmissionEvent({ widget: "Connect" });
    }

    if (props.location.hash) {
      const newUrl = urlValue + props.location.hash;
      setParentURL(newUrl);
    } else {
      setParentURL(urlValue);
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const GAEventTracking = () => {
    sendCommSubmissionEvent({ widget: "Connect" });
  };

  const submitForm = () => {
    GAEventTracking();

    let locationId = props.match.params.locationId;

    let param = {
      phone_number: phoneNumber,
      firstName: (name || "").trim(),
      link: parentURL,
      question: "Text me",
      language: getLanguage(props.match.params.lang),
    };

    QuestionsApi.textMe({ locationId, param });
    nextStep();
  };

  const getValidation = (value) => {
    let isValid = false;
    const phone = value || phoneNumber;

    if (currentStep === 1) {
      isValid = validatePhoneNumber(phone);
    } else if (currentStep === 2) {
      isValid = validateName(name);
    }

    return isValid;
  };

  const getSubmitButton = ({ onClick }) => {
    if (theme === "default") return;

    return (
      <ButtonWrapper>
        <NextButton onClick={onClick} disabled={!getValidation()} />
      </ButtonWrapper>
    );
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `61${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `61${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    getValidation(pasteValue);
    setPhoneNumber(pasteValue);
  };

  const getContent = () => {
    if (currentStep === 1) {
      return (
        <>
          <ContentWrapper>
            <PhoneField
              initialCountry={
                chatData &&
                parsePhoneNumber(chatData.organization_twilio_number) &&
                parsePhoneNumber(chatData.organization_twilio_number).country &&
                parsePhoneNumber(
                  chatData.organization_twilio_number
                ).country.toLowerCase()
              }
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              theme={theme}
              enableSubmit={getValidation()}
              onSubmit={nextStep}
              getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
            />
            {getSubmitButton({ onClick: nextStep })}
          </ContentWrapper>
          {footer}
        </>
      );
    } else if (currentStep === 2) {
      return (
        <>
          <ContentWrapper>
            <TextInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={strings.WHATS_YOUR_NAME}
              enableSubmit={getValidation()}
              onSubmit={submitForm}
              theme={theme}
            />
            {getSubmitButton({ onClick: submitForm })}
          </ContentWrapper>
          {footer}
        </>
      );
    } else if (currentStep === 3) {
      return <ThankYou theme={theme} />;
    }
  };

  const getNote = () => {
    if (theme === "default") return;

    let text = "";

    if (currentStep === 1) {
      text = "Enter there phone number for the next step";
    } else if (currentStep === 2) {
      text = "Enter your name for the next step";
    }

    return text && <Note text={text} />;
  };

  return (
    <Container>
      {getNote()}
      <MainContent>{getContent()}</MainContent>
    </Container>
  );
};

export default TextMeView;
