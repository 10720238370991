import React, { useCallback, useState } from "react";
import styled from "styled-components";
import MatadorFooter from "../Footer/DefaultFooter";
import ChatContent from "./ChatContent";
import chatSettingsMainActions from "../../../components/modules/chatSettingsMainActions";
import { useEffect } from "react";

import PageLoader from "../../../components/PageLoader";
import useAnalytics from "../../../utils/useAnalytics";
import { getFontFamily } from "../../utils/getFontFamily";
import { useContextData } from "../../contexts/ContextDataProvider";

const Container = styled.div`
  border-radius: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: ${(props) =>
    props.fontFamily || "Arial, 'Helvetica Neue', sans-serif"} !important;
`;

const MainContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    position: relative;
    display: block;
    width: 5px;
    cursor: pointer;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-left: -10px;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #00000033;
  }

  @supports (scrollbar-color: #00000033 transparent) {
    scrollbar-color: #00000033 transparent;
    scrollbar-width: thin;
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabItem = styled.div`
  display: ${(props) => props.display};
  height: 100%;
  visibility: ${(props) => props.visible || 1};
`;

const TabContainer = styled.div`
  height: 100%;
`;

const ChatLayout = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { chatData, setChatData } = useContextData();
  const [triggers, setTriggers] = useState();
  const [clearValues, setClearValues] = useState(0);
  const { match, history } = props;
  const [language, setLanguage] = useState("en");
  const [isClientUIX, setIsClientUIX] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [questionPicked, setQuestionPicked] = useState("");
  const [customActionData, setCustomActionData] = useState("");
  const [isCustomSubmitForm, setIsCustomSubmitForm] = useState();
  const [showBackIcon, setShowBackIcon] = useState(false);
  const [questionStatus, setQuestionStatus] = useState("");
  const { sendCommSubmissionEvent, sendCommEngagementEvent, d2cUtmTracking } =
    useAnalytics(chatData?.GATrackingNumber);

  useEffect(() => {
    setLanguageValue();
    loadData();
  }, []);

  useEffect(() => {
    if (chatData) {
      sendCommEngagementEvent({ widget: "Connect", status: "load" });
      checkIfCustomUIX();
    }
  }, [chatData]);

  useEffect(() => {
    if (questionPicked || selectedOrganization) {
      setShowBackIcon(true);
    } else {
      setShowBackIcon(false);
    }
  }, [questionPicked, selectedOrganization]);

  const checkIfCustomUIX = () => {
    if (
      chatData &&
      (chatData._location_id === "63923c4831b7db00122aba78" ||
        chatData._location_id === "6464fcf6d11d1500139a7b24" ||
        chatData._location_id === "645020b11cc20300137ed8d1" ||
        chatData._location_id === "641dac730ce3ba0013160afb" ||
        chatData._location_id === "601d524431c008001245d2eb" ||
        chatData._location_id === "64b5674e3f7d8000130c047d" ||
        chatData._location_id === "64dbb0cb28ee9a0013322aff" ||
        chatData._location_id === "64e380926b47fd0013096f84" ||
        chatData._location_id === "64df80884ff9ec0013fb270c" ||
        chatData._location_id === "64df89845146220013298f8e" ||
        chatData._location_id === "64df7f1b4ff9ec0013fb2552" ||
        chatData._location_id === "64df991e4ff9ec0013fb468c" ||
        chatData._location_id === "5eab099742f73e00123db711")
    ) {
      setIsClientUIX(true);

      if (
        chatData._location_id === "6464fcf6d11d1500139a7b24" ||
        chatData._location_id === "645020b11cc20300137ed8d1" ||
        chatData._location_id === "641dac730ce3ba0013160afb" ||
        chatData._location_id === "601d524431c008001245d2eb" ||
        chatData._location_id === "64b5674e3f7d8000130c047d" ||
        chatData._location_id === "64e380926b47fd0013096f84"
      ) {
        setIsCustomSubmitForm(true);
      }
    }
  };

  const handleMatadorCustomAction = (data) => {
    setCustomActionData(data);
  };

  const onMessageReceivedFromIframe = useCallback(
    ({ data }) => {
      if (data.id) {
        // preview update
        return setChatData({
          ...chatData,
          ...data,
        });
      }

      if (data.type === "MATADOR-CUSTOM-ACTION") {
        return handleMatadorCustomAction(data);
      }

      if (data.type === "MATADOR-CLEAR-CONNECT") {
        clear();
      }
    },
    [chatData]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  const setLanguageValue = () => {
    let languageValue = match.params.lang ? match.params.lang : "en";
    if (languageValue === "sp") {
      languageValue = "es";
    }
    setLanguage(languageValue);
  };

  const getToken = () => {
    return match.params.token ? match.params.token : 0;
  };

  const loadData = async () => {
    const token = getToken();

    let result = await chatSettingsMainActions.getSingleForWidget(
      { language: match.params.lang || "en" },
      token
    );

    if (result.success) {
      const { triggers } = result.data;
      setTriggers(triggers);
      setChatData(result.data.chatData);
    } else {
      history.push({
        pathname: "/error",
        state: { status: "400 " },
      });
    }
  };

  const clear = () => {
    goBack(false);
    setClearValues((prev) => prev + 1);
  };

  const changeTab = (index) => {
    if (index === 0) {
      clear();
    }
    setActiveTab(index);
  };

  const submissionGAevent = () => {
    sendCommSubmissionEvent({
      widget: "Connect",
      itemCategory: questionStatus,
    });
    d2cUtmTracking(chatData?._location_id);
  };

  const renderTabContent = () => {
    return (
      <TabContainer>
        <TabItem display={activeTab === 0 ? "block" : "none"}>
          <ChatContent
            {...props}
            chatData={chatData}
            triggers={triggers}
            clearValues={clearValues}
            isClientUIX={isClientUIX}
            setIsClientUIX={setIsClientUIX}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
            questionPicked={questionPicked}
            setQuestionPicked={setQuestionPicked}
            customActionData={customActionData}
            setCustomActionData={setCustomActionData}
            submissionGAevent={submissionGAevent}
            isCustomSubmitForm={isCustomSubmitForm}
            setIsCustomSubmitForm={setIsCustomSubmitForm}
            setShowBackIcon={setShowBackIcon}
            setQuestionStatus={setQuestionStatus}
          />
        </TabItem>
      </TabContainer>
    );
  };

  const goBack = (change = true) => {
    setQuestionPicked("");
    setQuestionStatus("");
    setSelectedOrganization(null);
    checkIfCustomUIX();
    if (change) {
      changeTab(0);
    }
  };

  const Content = chatData ? (
    <>
      <MainContent id="matador-connect-main-content">
        {renderTabContent()}
      </MainContent>
    </>
  ) : (
    <LoaderContainer>
      <PageLoader />
    </LoaderContainer>
  );

  return (
    <>
      <Container fontFamily={getFontFamily(chatData)}>
        {Content}
        <MatadorFooter token={getToken()} />
      </Container>
    </>
  );
};

export default ChatLayout;
