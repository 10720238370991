import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ChatBox from "./components/Chat/ChatBox";
import ErrorPage from "./components/ErrorPage";
import { history } from "./components/modules/UrlHelper";
import TextMeView from "./views/TextMe";
import Popup from "./components/Popup/Popup";
import ChatLayout from "./v2/components/Chat/ChatLayout";
import CtaView from "./views/CtaView";
import MobileCoupon from "./views/MobileCoupon";
import DesktopCoupon from "./views/DesktopCoupon";
import MobileCta from "./views/MobileCta";
import SubscriberGrowth from "./views/SubscriberGrowth";
import WizLayout from "./v2/components/Chat/Wiz/WizLayout";
import { ContextDataProvider } from "./v2/contexts/ContextDataProvider";

function App() {
  return (
    <ContextDataProvider>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/coupon/:token/:lang"
            render={(props) => <MobileCoupon {...props} />}
          />

          <Route
            exact
            path="/mobile-coupon/:token/:lang"
            render={(props) => <MobileCoupon {...props} />}
          />

          <Route
            exact
            path="/desktop-coupon/:token/:lang"
            render={(props) => <DesktopCoupon {...props} />}
          />

          <Route
            exact
            path="/chat_widget/:theme=default/:feature=text-me/:token/:lang/:locationId?"
            render={(props) => <TextMeView {...props} theme="default" />}
          />
          <Route
            exact
            path="/chat_widget/:theme=light/:feature=text-me/:token/:lang/:locationId?"
            render={(props) => <TextMeView {...props} theme="light" />}
          />

          <Route
            exact
            path="/chat_widget/:theme=default/:token/:lang"
            render={(props) => <ChatLayout {...props} theme="default" />}
          />

          <Route
            exact
            path="/wiz_widget/:theme=default/:token/:lang"
            render={(props) => <WizLayout {...props} theme="default" />}
          />
          <Route
            exact
            path="/chat_widget/:theme=light/:token/:lang"
            render={(props) => <ChatBox {...props} theme="light" />}
          />
          <Route
            exact
            path="/cta/:locationId/:lang"
            render={(props) => <CtaView {...props} />}
          />
          <Route
            exact
            path="/popup/:token/:lang"
            render={(props) => <Popup {...props} />}
          />
          <Route
            exact
            path="/mobile-cta/:token/:lang"
            render={(props) => <MobileCta {...props} />}
          />
          <Route
            exact
            path="/subscriber-growth/:token/:lang"
            render={(props) => <SubscriberGrowth {...props} />}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </ContextDataProvider>
  );
}

export default App;
