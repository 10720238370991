import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { strings } from "../../../utils/localization";

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InputElement = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid;
  border-color: #b5b5b5;
  font-size: 16px;
  padding: 5px;
  outline: none;
  border-bottom: ${(props) => (props.borderDisabled ? "none" : "")};
  background-color: ${(props) => props.backgroundColor};
  color: #17181b;

  &::placeholder {
    color: #b5b5b5;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translateY(-13px);
    font-size: 12px;
    color: ${(props) => props.color};
  }

  &:focus {
    border-bottom-color: ${(props) => props.color};

    &::placeholder {
      color: ${(props) => props.color};
    }
  }

  &:focus + label {
    color: ${(props) => props.color};
  }
`;

const PlaceholderLabel = styled.label`
  position: absolute;
  top: 0;
  left: 5px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: ${(props) => props.color};
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const MatadorInput = ({
  placeholder,
  color,
  required,
  onChange,
  value,
  customError,
  formSubmitted,
  onKeyDown,
  borderDisabled,
  backgroundColor,
  enableValidation = true,
  maxLength = 25,
  onBlur,
  onFocus,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      handleBlur();
    }
  }, [formSubmitted, handleBlur]);

  const handleChange = (event) => {
    onChange(event.target.value);
    setShowError(false);
    onChange && onChange(event.target.value);
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setIsFocused(true);
    setShowError(false);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocused(false);
    if (required && !value.trim()) {
      setShowError(true);
    }
  };

  return (
    <InputContainer>
      <InputElement
        id="matador-input-name"
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={isFocused ? "" : placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        color={color}
        onKeyDown={onKeyDown}
        borderDisabled={borderDisabled}
        backgroundColor={backgroundColor}
        maxLength={maxLength}
      />
      <PlaceholderLabel
        id="matador-input-label"
        style={{ visibility: isFocused ? "visible" : "hidden" }}
        color={color}
      >
        {placeholder}
      </PlaceholderLabel>
      {enableValidation && (
        <ErrorMessage
          style={{
            visibility: showError || customError ? "visible" : "hidden",
          }}
        >
          {customError || strings.REQUIRED_FIELD}
        </ErrorMessage>
      )}
    </InputContainer>
  );
};

export default MatadorInput;
