import axios from 'axios';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

let prefix = "http";
let socketPrefix = "ws";
if (process.env.REACT_APP_ENV === "PROD") {
    prefix = "https";
    socketPrefix = "wss";
}
let BASE_URL = `${prefix}://${process.env.REACT_APP_DOMAIN}/api/v1`;
let SOCKET_URL = `${socketPrefix}://${process.env.REACT_APP_SOCKET_DOMAIN}`;

export const urlConfig = { BASE_URL, SOCKET_URL};

const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 30000;

export const siteUrl = window.location.origin;

export const history = createBrowserHistory();


export function redirect(path) {
    history.push(path);
}


export function urlServer(path = "") {
    return BASE_URL + path;
}

export function urlSocket(path = "") {
    return SOCKET_URL + path;
}

export async function fetchFromUrlPOSTAsync(url, parameters) {
    return await fetchAS("POST", url, parameters);
}

export async function fetchFromUrlGETAsync(url, parameters) {
    return await fetchAS("GET", url, parameters);
}

export async function fetchFromUrlPATCHAsync(url, parameters) {
    return await fetchAS("PATCH", url, parameters);
}

export async function fetchAS(method, url, parameters) {
    let fetchUrl = urlServer(url);
    let toSend = {};
    parameters.forEach((par) => {
        toSend[par.key] = par.value;
    });
    try {
        let body = undefined;
        let params = undefined;
        if (method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE") {
            body = JSON.stringify(toSend);
        } else if (method === "GET") {
            params = toSend;
        }

        const config = {
            url: fetchUrl,
            data: body,
            params,
            method,
            timeout: 30000,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        const response = await axiosInstance.request(config);
        const obj = {
            success: response.status >= 200 && response.status < 300,
        };
        let resJson;
        try {
            resJson = response.data;

            if (obj.success) {
                obj.data = resJson;
            } else {
                obj.errCode = resJson.code;
                obj.errMsg = resJson.message;
                if (resJson.errors) {
                    obj.errors = resJson.errors;
                } 
            }
        } catch (err) {
            redirect({
                pathname : "/error",
                state: { status: obj.errCode }
            });
        }
        return obj;
    } 
          catch (err) {
        console.error(err);
        return { success: false, errMsg: _.get(err.response, 'data.message', "Connection error.") };
    }
}

export async function uploadAndFetchPOStFile(url, obj) {
    const storeToken = { accessToken: cookies.get('token') };
    const config = {
        headers: {
            Authorization: `Bearer ${storeToken.accessToken}`,
            'Content-Type': 'multipart/form-data',
        },
    };
    const data = new FormData();
    Object.keys(obj).forEach(key => {
        data.append(key, obj[key]);
    });
    try {
        let fetchUrl = urlServer(url);
        let res = await axios.post(fetchUrl, data, config);
        return { success: res.status >= 200 && res.status < 300, data: res.data };
    } catch (err) {
        console.error(err);
        redirect({
            pathname : "/error",
            state: { status: obj.errCode }
        });
        return { success: false, errMsg: _.get(err.response, 'data.message', "Connection error") };
    }
}

export default BASE_URL;