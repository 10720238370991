import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useClipboard from "../../../utils/useClipboard";
import PhoneNumberField from "../../../components/Fields/PhoneNumberField";
import { validatePhoneNumber } from "../../../utils/validation";
import { strings } from "../../../utils/localization";

const InputContainer = styled.div`
  position: relative;
  width: 100%;

  .matadot-live-chat-123789-main-input {
    border: none;
    border-bottom: 2px solid;
    border-color: #b5b5b5;
    font-size: 16px;
    outline: none !important;
    border-radius: 0;
    width: 100%;

    &::placeholder {
      color: #b5b5b5;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      transform: translateY(-20px) !important;
      font-size: 12px;
      color: ${(props) => props.color};
    }

    &:focus {
      border-bottom-color: ${(props) => props.color};

      &::placeholder {
        color: ${(props) => props.color};
      }
    }

    &:focus + label {
      color: ${(props) => props.color};
    }
  }

  .flag-dropdown {
    border-radius: 10px 0;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const PlaceholderLabel = styled.label`
  position: absolute;
  top: -25px;
  left: 5px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: ${(props) => props.color};
`;

const MatadorPhoneInput = ({
  placeholder,
  color,
  value,
  organizationCountryCode,
  onChange,
  required,
  formSubmitted,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldTouched, setFieldTouched] = useState(false);

  const getValue = () => {
    if (
      organizationCountryCode?.toLowerCase() === "au" &&
      !value &&
      !fieldTouched
    ) {
      return "+04";
    }
    return value;
  };

  const onChangeHandler = (value) => {
    setErrorMessage("");
    setFieldTouched(true);
    if (
      organizationCountryCode?.toLowerCase() === "au" &&
      !value &&
      !fieldTouched
    ) {
      return onChange(`+04${value}`);
    }
    return onChange(value);
  };

  useEffect(() => {
    if (formSubmitted) {
      handleBlur();
    }
  }, [formSubmitted, handleBlur]);

  const { getPhoneNumberFromClipboard } = useClipboard({
    setPhoneNumber: onChange,
    countryCode: organizationCountryCode,
  });

  const handleFocus = () => {
    setIsFocused(true);
    setErrorMessage("");
  };

  const handleBlur = () => {
    setIsFocused(false);
    const isMobileNumberValid = validatePhoneNumber(value);

    if (required && (!value.trim() || !isMobileNumberValid)) {
      setErrorMessage(strings.REQUIRED_FIELD);
    }

    if (!isMobileNumberValid) {
      setErrorMessage(strings.INVALID_PHONE_NUMBER);
    }
  };

  return (
    <InputContainer color={color}>
      <PhoneNumberField
        defaultCountryFlag={organizationCountryCode}
        getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        borderRadius={0}
        value={getValue()}
        onChange={onChangeHandler}
      />
      <PlaceholderLabel
        style={{ visibility: isFocused ? "visible" : "hidden" }}
        color={color}
      >
        {placeholder}
      </PlaceholderLabel>
      <ErrorMessage
        style={{ visibility: !!errorMessage ? "visible" : "hidden" }}
      >
        {errorMessage}
      </ErrorMessage>
    </InputContainer>
  );
};

export default MatadorPhoneInput;
