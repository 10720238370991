import React, { useCallback, useEffect, useState } from "react";
import MatadorInput from "../v2/components/Input/MatadorInput";
import styled, { createGlobalStyle } from "styled-components";
import { MessageIconNew, SVGSuccess } from "../components/Icons/Icons";
import { ReactComponent as CloseIcon } from "../assets/images/common/closeIcon.svg";
import MatadorPhoneInput from "../v2/components/Input/MatadorPhoneInput";
import querystring from "querystring";
import { getLanguage, strings } from "../utils/localization";
import QuestionsApi from "../api/questions-api";
import subscriberGrowthActions from "../components/modules/subscriberGrowthActions";
import { validateEmail } from "../utils/validation";
import useAnalytics from "../utils/useAnalytics";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'General Sans';
    src: url('https://fonts.cdnfonts.com/css/general-sans') format('truetype');
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 5% 30%;

  @media (max-width: 768px) {
    padding: 5% 5%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

const CloseContainer = styled.div`
  cursor: pointer;
  padding: 10px;

  svg {
    height: 15px;
    width: 15px;
  }
`;

const Title = styled.p`
  color: #25253b;
  font-size: 36px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 39.6px;
  text-align: center;
  margin-bottom: 0px;
  top: 0;
  min-height: 39.6px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 18px;
  margin-bottom: 32px;
  color: #4a4a4a;
  text-align: center;
  min-height: 20px;
`;

const Section = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 26px;
  width: 100%;
`;

const SubmitButton = styled.div`
  border-radius: 20px;
  background-color: ${(props) => props.color};
  padding: 15px 62px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TermsSection = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 60px;
  justify-content: flex-start;
`;

const Checkbox = styled.input`
  width: 34px;
  height: 34px;
  margin-right: 12px;
  padding: 10px;
  &:checked {
    accent-color: ${(props) => props.color};
  }
`;

const Label = styled.p`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  margin: 0px;
  color: ${(props) => props.color};
`;

const PhoneContainer = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const SuccessContainer = styled.div`
  height: 70%;
  display: flex;
  align-items: center;
`;

const SubscriberGrowth = ({ location, match }) => {
  const { token: locationId } = match.params;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [parentURL, setParentURL] = useState();
  const [language, setLanguage] = useState("en");

  const [termsAccepted, setTermsAccepted] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const [dataInfo, setDataInfo] = useState();
  const { primaryColor } = dataInfo || {};
  const [emailError, setEmailError] = useState("");
  const {
    sendCommSubmissionEvent,
    sendCommEngagementEvent,
    sendCtaInteractionEvent,
  } = useAnalytics(dataInfo?.GATrackingNumber);

  const close = () => {
    window.top.postMessage("close-subscriber-growth", "*");
  };

  useEffect(() => {
    if (dataInfo?.location_disabled || dataInfo?.organization_disabled) {
      close();
    }
  }, [dataInfo]);

  useEffect(() => {
    if (dataInfo) {
      sendCommEngagementEvent({ widget: "Subscriber Growth", status: "load" });
    }
  }, [dataInfo]);

  useEffect(() => {
    getParentUrl();
    getData();
    setLanguageValue();
  }, []);

  useEffect(() => {
    if (email) {
      const valid = validateEmail(email);
      setEmailError(valid ? "" : strings.ENTER_VALID_EMAIL);
    } else {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  const getData = async () => {
    const result = await subscriberGrowthActions.getData(locationId);

    if (result.success) {
      setDataInfo(result.data);
    }
  };

  const getParentUrl = () => {
    let { lang } = match.params;

    let params = querystring.parse(location.search);
    let urlValue = decodeURI(params["?url"]);

    setLanguage(lang);

    if (location.hash) {
      const newUrl = urlValue + location.hash;
      setParentURL(newUrl);
    } else {
      setParentURL(urlValue);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = true;
    setFormSubmitted(true);
    const emailValid = validateEmail(email);

    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !phoneNumber.trim()
    ) {
      isFormValid = false;
    }

    if (isFormValid && emailValid) {
      sendData();
    }
  };

  const GAEventTracking = () => {
    sendCommSubmissionEvent({ widget: "Subscriber Growth" });
  };

  const setLanguageValue = () => {
    let { lang } = match.params;

    let languageValue = lang ? lang : "en";
    if (languageValue === "es") {
      languageValue = "sp";
    }

    setLanguage(languageValue);
    if (languageValue === "sp") {
      strings.setLanguage("es");
    } else {
      strings.setLanguage(languageValue);
    }
  };

  const sendData = async () => {
    GAEventTracking();

    let param = {
      firstName,
      lastName,
      phone_number: phoneNumber,
      question: "Subscriber growth",
      language: getLanguage(language),
      email,
    };

    if (parentURL) {
      param.link = parentURL || window.location.href;
    }

    setDataSent(true);

    await QuestionsApi.subscriberGrowth({
      locationId,
      param,
    });

    setTimeout(() => {
      close();
    }, 1300);
  };

  const onMessageReceivedFromIframe = useCallback(
    ({ data }) => {
      setDataInfo({
        ...dataInfo,
        ...data,
      });
    },
    [dataInfo]
  );

  const onClick = () => {
    sendCtaInteractionEvent({ widget: "Subscriber Growth" });
  };

  const successMessage = (
    <SuccessContainer>
      <div>
        <SVGSuccess height="170" width="170" />
      </div>
      <div className="circle" style={{ "animation-delay": "-2s" }} />
      <div className="circle" style={{ "animation-delay": "-1s" }} />
      <div className="circle" style={{ "animation-delay": "1s" }} />
    </SuccessContainer>
  );

  const getMainContent = () => {
    if (!dataInfo) {
      return null;
    }

    if (dataSent) {
      return successMessage;
    }

    return (
      <>
        <Title>{dataInfo?.title[language] || ""}</Title>
        <Subtitle>{dataInfo?.description[language] || ""}</Subtitle>
        <Section>
          <MatadorInput
            placeholder={strings.FIRST_NAME}
            color={primaryColor}
            required
            value={firstName}
            onChange={setFirstName}
            formSubmitted={formSubmitted}
          />
          <MatadorInput
            placeholder={strings.LAST_NAME}
            color={primaryColor}
            required
            value={lastName}
            onChange={setLastName}
            formSubmitted={formSubmitted}
          />
        </Section>
        <Section>
          <MatadorInput
            placeholder={strings.EMAIL}
            color={primaryColor}
            required
            value={email}
            onChange={setEmail}
            customError={emailError}
            formSubmitted={formSubmitted}
            maxLength={100}
          />
        </Section>
        <Section>
          <PhoneContainer>
            <MatadorPhoneInput
              value={phoneNumber}
              onChange={setPhoneNumber}
              organizationCountryCode={dataInfo?.organizationCountryCode?.toLowerCase()}
              placeholder={strings.PHONE_NUMBER}
              color={primaryColor}
              required
              formSubmitted={formSubmitted}
            />
          </PhoneContainer>
        </Section>
        <TermsSection>
          <Checkbox type="checkbox" color={primaryColor} checked readOnly />
          <Label color={!termsAccepted && formSubmitted ? "red" : "#000"}>
            {strings.ACCEPT_TERMS_TEXT}
          </Label>
        </TermsSection>

        <SubmitButton onClick={handleSubmit} color={primaryColor}>
          {MessageIconNew()}
          {strings.CHAT_TEXT_ME}
        </SubmitButton>
      </>
    );
  };

  return (
    <Container onClick={onClick}>
      <GlobalStyle />
      <Header>
        <CloseContainer onClick={close}>
          <CloseIcon stroke="#387D65" />
        </CloseContainer>
      </Header>
      {getMainContent()}
    </Container>
  );
};

export default SubscriberGrowth;
