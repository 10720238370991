import React, { useEffect, useRef, useState } from "react";
import PopupPhoneField from "../Fields/PopupPhoneField";
import "react-phone-input-2/lib/style.css";
import "../../styles/Popup.css";
import popupMainActions from "../modules/popupMainActions";
import querystring from "querystring";
import QuestionsApi from "../../api/questions-api";
import { ReactComponent as CloseIcon } from "../../assets/images/common/closeIcon.svg";
import { SVGSuccess } from "../Icons/Icons";
import { getLanguage, strings } from "../../utils/localization";
import { validatePhoneNumber } from "../../utils/validation";
import AcceptTermsFooter from "../../v2/components/Footer/AcceptTermsFooter";
import useAnalytics from "../../utils/useAnalytics";
import { ReactComponent as NewLogo } from "../../assets/images/common/new_logo.svg";

function Popup(props) {
  const [visible, setVisible] = useState(true);
  const [popupData, setPopupData] = useState();
  const [sentNumber, setSentNumber] = useState(false);
  const { token: locationId } = props.match.params;
  let { lang } = props.match.params;
  const [parentURL, setParentURL] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disable, setDisable] = useState(true);
  const wrapperRef = useRef(null);
  const {
    sendCommSubmissionEvent,
    sendCommEngagementEvent,
    sendCtaInteractionEvent,
  } = useAnalytics(popupData?.GATrackingNumber);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          close();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  if (lang === "es") {
    lang = "sp";
  }

  useEffect(() => {
    setLanguageValue();

    if (popupData) {
      sendCommEngagementEvent({ widget: "Exit Intent", status: "load" });
    }
  }, [popupData]);

  const setLanguageValue = () => {
    let { lang } = props.match.params;

    let languageValue = lang ? lang : "en";
    if (languageValue === "es") {
      languageValue = "sp";
    }

    if (languageValue === "sp") {
      strings.setLanguage("es");
    } else {
      strings.setLanguage(languageValue);
    }
  };

  useEffect(() => {
    const fill = async () => {
      let result = await popupMainActions.getPopup(locationId);

      if (result.success) {
        setPopupData(result.data);
      }
    };

    if (locationId) {
      fill();
    }

    loadData();
  }, [locationId]);

  const loadData = () => {
    let params = querystring.parse(props.location.search);
    let urlValue = decodeURI(params["?url"]);

    if (props.location.hash) {
      const newUrl = urlValue + props.location.hash;
      setParentURL(newUrl);
    } else {
      setParentURL(urlValue);
    }
  };

  const close = () => {
    window.top.postMessage("matador_coupon_message_hidden", "*");
    setVisible(false);
  };

  const GAEventTracking = () => {
    sendCommSubmissionEvent({ widget: "Exit Intent" });
  };

  const submitForm = async () => {
    let locationId = props.match.params.token;

    let param = {
      phone_number: phoneNumber,
      link: parentURL,
      question: "Exit intent call to action",
      language: getLanguage(lang),
    };

    GAEventTracking();

    QuestionsApi.exitIntent({ locationId, param });

    setSentNumber(true);

    setTimeout(() => close(), 3000);
  };

  const handleChange = (value) => {
    setDisable(true);
    setPhoneNumber(value);

    const validPhone = validatePhoneNumber(value);

    setDisable(!validPhone);
  };

  const getPhoneNumberFromClipboard = (event) => {
    setDisable(true);

    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (countryCode?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `61${pasteValue}`;
        setDisable(false);
      }
      if (pasteValue.length >= 11) {
        pasteValue = `61${pasteValue && pasteValue.slice(2)}`;
        setDisable(false);
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
        setDisable(false);
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
        setDisable(false);
      }
    }

    const validPhone = validatePhoneNumber(pasteValue);
    setDisable(!validPhone);
    setPhoneNumber(pasteValue);
  };

  if (!popupData) return null;

  const header =
    popupData?.header?.find((x) => x.language === lang)?.text ||
    popupData.header?.find((x) => x.language === "en")?.text;
  const title =
    popupData?.title?.find((x) => x.language === lang)?.text ||
    popupData.title?.find((x) => x.language === "en")?.text;
  const description =
    popupData?.description?.find((x) => x.language === lang)?.text ||
    popupData.description?.find((x) => x.language === "en")?.text;
  const buttonTexts =
    popupData?.buttonTexts?.find((x) => x.language === lang)?.text ||
    popupData.buttonTexts?.find((x) => x.language === "en")?.text;
  const countryCode = popupData?.organizationInfo.organizationCountryCode;

  const confirmItem = (
    <div className="confirm_icon_container">
      <div className="confirm_icon_item">
        <SVGSuccess height="170" width="170" />
      </div>
      <div className="circle" style={{ "animation-delay": "-2s" }} />
      <div className="circle" style={{ "animation-delay": "-1s" }} />
      <div className="circle" style={{ "animation-delay": "1s" }} />
    </div>
  );

  const onClick = () => {
    sendCtaInteractionEvent({ widget: "Exit Intent" });
  };

  return (
    <>
      {visible && (
        <div onClick={onClick}>
          <div className="darkBG" />
          <div
            className={
              popupData.photo
                ? "popup_main-container popup_main-container--image"
                : "popup_main-container"
            }
          >
            <div
              className="popup_container"
              style={{
                backgroundColor: popupData.backgroundColor,
              }}
              ref={wrapperRef}
            >
              <div
                className="popup_left-side"
                style={
                  !popupData.photo
                    ? {
                        paddingLeft: "0",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                {!sentNumber ? (
                  <>
                    <div>
                      <div
                        onClick={close}
                        style={{
                          position: "absolute",
                          top: 20,
                          right: 20,
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon height={15} width={15} stroke={"#808080"} />
                      </div>
                      <p
                        className="popup_subtitle"
                        style={{
                          color: popupData.headerColor,
                        }}
                      >
                        {header}
                      </p>
                      <p
                        className="popup_header"
                        style={{
                          color: popupData.titleColor,
                        }}
                      >
                        {title}
                      </p>
                      <p
                        className="popup_description"
                        style={{
                          color: popupData.descriptionColor,
                        }}
                      >
                        {description}
                      </p>
                      <PopupPhoneField
                        initialCountry={countryCode?.toLowerCase() || "us"}
                        handleChange={handleChange}
                        getPhoneNumberFromClipboard={
                          getPhoneNumberFromClipboard
                        }
                        value={phoneNumber}
                      />
                      <button
                        className="popup_button"
                        onClick={submitForm}
                        disabled={disable}
                        style={{
                          color: popupData.buttonTextColor,
                          background: popupData.buttonColor,
                          cursor: disable && "not-allowed",
                          opacity: disable && "0.3",
                        }}
                      >
                        {buttonTexts}
                      </button>
                      <AcceptTermsFooter
                        color={popupData.buttonColor}
                        termsLink={popupData.termsConditionsLink}
                      />
                    </div>
                  </>
                ) : (
                  confirmItem
                )}
                <div className={"popup_logo"}>
                  <p>Powered By</p>
                  <NewLogo />
                </div>
              </div>
              {popupData.photo && (
                <div className="popup_right-side" style={{ minWidth: "20rem" }}>
                  <img
                    className="img-fluid"
                    src={popupData.photo}
                    alt={popupData.title}
                    style={{ objectFit: "cover" }}
                  />
                  <div
                    className="overlay"
                    style={{
                      background: popupData.buttonColor,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
